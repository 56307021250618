import React, { useMemo } from 'react';
import { CDataTable, CBadge } from '@coreui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import { cilBan } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { formatDate } from 'utils/helpers';
import Pagination from 'components/common/Pagination';
import s from './MessagesTable.module.scss';

const getBadge = (status) => {
  switch (status) {
    case true:
      return 'success';
    case false:
      return 'warning';
    default:
      return 'primary';
  }
};

const NoItemsView = () => (
  <div className={s.noItemsView}>
    <div className={s.text}>
      <FormattedMessage id="messages.empty" />
    </div>
    <CIcon content={cilBan} size="2xl" />
  </div>
);

const formatUserMessage = (message) => (message.length > 100 ? `${message.slice(0, 110)}...` : message);

const MessagesTable = ({ onRowClick, messages, changePage, currentPage, total }) => {
  const { formatMessage: t } = useIntl();

  const fields = useMemo(
    () => [
      { key: 'name', label: t({ id: 'messages.name' }) },
      { key: 'message', label: t({ id: 'messages.message' }) },
      { key: 'date', label: t({ id: 'messages.date' }) },
      { key: 'responded', label: t({ id: 'messages.responded' }) },
    ],
    [t],
  );

  const items = useMemo(
    () =>
      messages.map(({ _id, status, created_at, message, email, sender }) => ({
        id: _id,
        email,
        name: sender,
        date: formatDate(created_at),
        responded: status.responded,
        _classes: !status.read && s.striped,
        message: formatUserMessage(message),
        fullMessage: message,
      })),
    [messages],
  );

  return (
    <>
      <CDataTable
        clickableRows
        noItemsViewSlot={<NoItemsView />}
        onRowClick={(message) => onRowClick(message)}
        addTableClasses={s.messageTable}
        hover
        items={items}
        fields={fields}
        size="sm"
        itemsPerPage={COUNT_TABLE_RECORDS}
        scopedSlots={{
          responded: (item) => (
            <td>
              <CBadge color={getBadge(item.responded)}>
                {item.responded ? t({ id: 'messages.responded' }) : t({ id: 'messages.unresponded' })}
              </CBadge>
            </td>
          ),
        }}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default MessagesTable;

import { CCard, CCardBody, CCardHeader, CCol, CDataTable } from '@coreui/react';
import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import useTotalCO2SavingsReport from 'hooks/useTotalCO2SavingsReport';
import { pick } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { value2LocaleValue } from 'utils/helpers';
import s from './CO2SavingsReport.module.scss';

const co2SavingsKeys = [
  'new_windows',
  'insulation_top_ceiling',
  'insulation_basement_ceiling',
  'insulation_facade',
  'controlled_living_space_ventilation',
  'heating_system',
  'solar_power_system',
];

const TotalCO2SavingsTable = ({ totalCO2SavingsReport, t }) => {
  const tableHead = useMemo(
    () => [
      { key: 'relevantCategory', label: t({ id: 'co2_report.category_name' }) },
      { key: 'countOfProjectsWithStatusPlanned', label: t({ id: 'co2_report.number_of_status_planned' }) },
      { key: 'plannedCO2Savings', label: t({ id: 'co2_report.co2_savings' }) },
      { key: 'countOfProjectsWithStatusProgress', label: t({ id: 'co2_report.number_of_status_progress' }) },
      { key: 'progressCO2Savings', label: t({ id: 'co2_report.co2_savings' }) },
      { key: 'countOfProjectsWithStatusDone', label: t({ id: 'co2_report.number_of_status_done' }) },
      { key: 'doneCO2Savings', label: t({ id: 'co2_report.co2_savings' }) },
    ],
    [t],
  );

  const co2SavingsItems = useMemo(() => Object.entries(pick(totalCO2SavingsReport, co2SavingsKeys)), [
    totalCO2SavingsReport,
  ]);
  const co2SavingsTotal = useMemo(
    () => co2SavingsItems.reduce((sum, [, { co2_savings }]) => sum + Math.round(co2_savings / 1000), 0),
    [co2SavingsItems],
  );

  const items = useMemo(() => {
    const tableRows = co2SavingsItems.map(([categoryKey, co2SavingsCategory]) => {
      const { co2_savings, projects_count } = co2SavingsCategory;
      return {
        relevantCategory: t({ id: `co2_report.${categoryKey}` }),
        countOfProjectsWithStatusPlanned: value2LocaleValue(projects_count),
        plannedCO2Savings: value2LocaleValue(Math.round(co2_savings / 1000)),
        countOfProjectsWithStatusProgress: t({ id: 'co2_report.coming_soon' }),
        progressCO2Savings: t({ id: 'co2_report.coming_soon' }),
        countOfProjectsWithStatusDone: t({ id: 'co2_report.coming_soon' }),
        doneCO2Savings: t({ id: 'co2_report.coming_soon' }),
      };
    });
    const tableFooterRow = {
      relevantCategory: ' ',
      countOfProjectsWithStatusPlanned: ' ',
      plannedCO2Savings: value2LocaleValue(co2SavingsTotal),
      countOfProjectsWithStatusProgress: ' ',
      progressCO2Savings: ' ',
      countOfProjectsWithStatusDone: ' ',
      doneCO2Savings: ' ',
    };
    tableRows.push(tableFooterRow);
    return tableRows;
  }, [co2SavingsItems, co2SavingsTotal, t]);

  return <CDataTable addTableClasses={s.co2ReportTable} responsive hover items={items} fields={tableHead} size="sm" />;
};

const CO2SavingsReport = () => {
  const { formatMessage: t } = useIntl();
  const [totalCO2SavingsReport, loading] = useTotalCO2SavingsReport();

  return (
    <CCol xs="12" lg="12">
      <CCard>
        <CCardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4>{t({ id: 'page_titles.co2_report' })}</h4>
          <Link to={routePatterns.co2SavingsByProjects.stringify({ page: 1 })}>
            <Button>{t({ id: 'co2_report.go_to_details' })}</Button>
          </Link>
        </CCardHeader>
        <CCardBody>
          {!loading ? <TotalCO2SavingsTable {...{ totalCO2SavingsReport, t }} /> : <Loading size={30} />}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default CO2SavingsReport;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoginWithTwoFA from 'pages/LoginWithTwoFA';
import routePaths from './route-paths';
import Login from '../pages/Login';

export const authRoutes = [
  {
    path: routePaths.login,
    component: Login,
  },
  {
    path: routePaths.loginTwoFA,
    component: LoginWithTwoFA,
  },
];

const AuthRouter = () => (
  <Switch>
    {authRoutes.map(({ path, component, ...rest }) => (
      <Route key={path} path={path} component={component} exact {...rest} />
    ))}
    <Route render={() => <Redirect to={routePaths.login} exact />} />
  </Switch>
);

export default AuthRouter;

/* eslint-disable react/button-has-type */
import React from 'react';
import classnames from 'classnames';
import Loading from './Loading';
import styles from './Button.module.scss';

export const colorToClassNameMap = {
  primary: styles.primary,
  transparent: styles.transparent,
  secondary: styles.secondary,
  danger: styles.danger,
};

export default function Button({ children, className, disabled, color, loading, type = 'button', onClick, ...rest }) {
  return (
    <button
      className={classnames(styles.btn, colorToClassNameMap[color], className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <div style={{ visibility: loading ? 'hidden' : 'visible' }}>{children}</div>
      {loading ? (
        <div className={styles.btnLoading}>
          <Loading size={25} color="#fff" />
        </div>
      ) : null}
    </button>
  );
}

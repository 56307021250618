import React from 'react';
import settings from '../config/settings';

export const AppContext = React.createContext({
  locale: settings.defaultLocale,
  showSidebar: 'responsive',
});

export const useSidebar = () => {
  const { showSidebar, setShowSidebar } = React.useContext(AppContext);

  const toggleSidebar = React.useCallback(() => {
    const val = [true, 'responsive'].includes(showSidebar) ? false : 'responsive';
    setShowSidebar(val);
  }, [setShowSidebar, showSidebar]);

  const toggleSidebarMobile = React.useCallback(() => {
    const val = [false, 'responsive'].includes(showSidebar) ? true : 'responsive';
    setShowSidebar(val);
  }, [setShowSidebar, showSidebar]);

  return { showSidebar, toggleSidebar, toggleSidebarMobile };
};

export const AppContextProvider = ({ children, ...value }) => {
  const [showSidebar, setShowSidebar] = React.useState('responsive');
  return <AppContext.Provider value={{ value, showSidebar, setShowSidebar }}>{children}</AppContext.Provider>;
};
export const AppContextConsumer = AppContext.Consumer;

export const useAppContext = () => React.useContext(AppContext);

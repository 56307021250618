import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { ME_QUERY } from 'hooks/useUser';
import Cookies from 'js-cookie';

const LOGOUT_MUTATION = gql`
  mutation logout {
    logout {
      success
    }
  }
`;

const useLogout = () => {
  const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);

  const logout = useCallback(() => {
    Cookies.remove('token');
    logoutMutation();

    client.writeQuery({ query: ME_QUERY, data: { me: null } });
    client.cache.reset();
  }, [client, logoutMutation]);

  return logout;
};

export default useLogout;

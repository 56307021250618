import CIcon from '@coreui/icons-react';
import React, { useMemo } from 'react';
import { CDataTable } from '@coreui/react';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import { omit, get } from 'lodash';
import { cibAdobeAcrobatReader } from '@coreui/icons';
import { formatCurrency, formatDate } from 'utils/helpers';
import Pagination from 'components/common/Pagination';
import s from './CustomerDataAnalyticsTable.module.scss';

const getTableHead = (t) => {
  return [
    { key: 'name', label: t({ id: 'customer_data_analytics.customer_name' }) },
    { key: 'email', label: t({ id: 'customer_data_analytics.customer_email' }) },
    { key: 'clientIP', label: t({ id: 'customer_data_analytics.ip_address' }) },
    { key: 'amount_with_loan_costs', label: t({ id: 'customer_data_analytics.amount_with_loan_costs' }) },
    { key: 'last_project_type', label: t({ id: 'customer_data_analytics.last_project_type' }) },
    { key: 'last_project_created_at', label: t({ id: 'customer_data_analytics.last_project_created_at' }) },
    { key: 'created_at', label: t({ id: 'customer_data_analytics.created_at' }) },
    { key: 'pdf', label: t({ id: 'customer_data_analytics.pdf' }) },
    { key: 'alone_or_partner', label: t({ id: 'customer_data_analytics.alone_or_partner' }) },
    { key: 'age', label: t({ id: 'customer_data_analytics.age' }) },
    { key: 'kids_quantity', label: t({ id: 'customer_data_analytics.kids_quantity' }) },
    { key: 'monthly_net_salary', label: t({ id: 'customer_data_analytics.monthly_net_salary' }) },
    { key: 'monthly_net_salary_partner', label: t({ id: 'customer_data_analytics.monthly_net_salary_partner' }) },
    { key: 'other_revenues', label: t({ id: 'customer_data_analytics.other_revenues' }) },
    { key: 'new_car', label: t({ id: 'customer_data_analytics.new_car' }) },
    { key: 'hobbies', label: t({ id: 'customer_data_analytics.hobbies' }) },
    { key: 'pension_and_insurances', label: t({ id: 'customer_data_analytics.pension_and_insurances' }) },
    { key: 'socializing', label: t({ id: 'customer_data_analytics.socializing' }) },
    { key: 'luxuries', label: t({ id: 'customer_data_analytics.luxuries' }) },
    { key: 'taking_a_break', label: t({ id: 'customer_data_analytics.taking_a_break' }) },
    { key: 'technology', label: t({ id: 'customer_data_analytics.technology' }) },
  ];
};

const formatTableRecord = (tableRecord, t) => {
  const { user, amount_with_loan_costs, last_project_type, last_project_created_at, budgetEstimation } = tableRecord;
  const { formData, pdf, created_at } = budgetEstimation;
  const firstName = get(user, 'profile.firstName', '-');
  const lastName = get(user, 'profile.lastName', '-');
  const name = `${firstName} ${lastName}`;
  const email = get(user, 'emails[0].address', '-');
  const clientIP = get(user, 'meta.clientIP', '-');

  const selectValueKeys = [
    'hobbies',
    'pension_and_insurances',
    'socializing',
    'new_car',
    'luxuries',
    'taking_a_break',
    'technology',
  ];
  const localizedValues = selectValueKeys.reduce(
    (acc, key) => ({ ...acc, [key]: formData[key] ? t({ id: `select_values.${formData[key]}` }) : '-' }),
    {},
  );

  const userData = { name, email, clientIP, age: formData.age };
  const budgetData = {
    pdf,
    created_at: formatDate(created_at),
    ...omit(formData, selectValueKeys),
    ...localizedValues,
  };

  const projectData = {
    amount_with_loan_costs: last_project_type ? formatCurrency(amount_with_loan_costs) : '-',
    last_project_type: last_project_type ? t({ id: `projects.types.${last_project_type}` }) : '-',
    last_project_created_at: last_project_created_at ? formatDate(new Date(last_project_created_at)) : '-',
  };

  return { ...budgetData, ...userData, ...projectData };
};

const CustomerDataAnalyticsTable = ({ customerDataRecords, changePage, currentPage, total, t }) => {
  const tableHead = useMemo(() => getTableHead(t), [t]);
  const items = useMemo(() => customerDataRecords.map((record) => formatTableRecord(record, t)), [
    customerDataRecords,
    t,
  ]);

  return (
    <>
      <CDataTable
        addTableClasses={s.projectsTable}
        hover
        items={items}
        fields={tableHead}
        size="sm"
        itemsPerPage={COUNT_TABLE_RECORDS}
        scopedSlots={{
          pdf: ({ pdf }) => (
            <td>
              <a href={pdf} target="_blank" rel="noopener noreferrer">
                <div className={s.pdfButton}>
                  <CIcon content={cibAdobeAcrobatReader} />
                </div>
              </a>
            </td>
          ),
        }}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default CustomerDataAnalyticsTable;

import React from 'react';
import { CCard, CCardHeader, CCol, CFormGroup, CLabel, CInput } from '@coreui/react';
import { useIntl } from 'react-intl';
import useCustomerDataAnalytics from 'hooks/useCustomerDataAnalytics';
import Loading from 'components/common/Loading';
import usePagination from 'hooks/usePagination';
import CustomerDataAnalyticsTable from 'components/customerDataAnalytics/CustomerDataAnalyticsTable';
import useFilterValue from 'hooks/useFilterValue';
import Button from 'components/common/Button';
import useFetchXlsxFile from 'hooks/useFetchXlsxFile';
import s from './CustomerDataAnalytics.module.scss';

const xlsxUrl = '/_pdf/xls_report_customer_data_analytics';

const CustomerDataAnalytics = () => {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'customerDataAnalytics' });
  const [executeRequest, fetching] = useFetchXlsxFile({ url: xlsxUrl, fileName: 'CustomerAnalytics' });
  const { filter, handleChange, debouncedFilterValue } = useFilterValue({ changePage });
  const { customerDataRecords, total, loading } = useCustomerDataAnalytics({
    currentPage,
    filter: debouncedFilterValue,
  });

  return (
    <CCol xs="12" lg="12">
      <CCard>
        <CCardHeader className={s.header}>
          <h4>{t({ id: 'page_titles.customer_data_analytics' })}</h4>
          <Button color="primary" onClick={executeRequest} disabled={fetching} loading={fetching}>
            {t({ id: 'customer_data_analytics.generate_report' })}
          </Button>
        </CCardHeader>

        <CFormGroup>
          <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
          <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
        </CFormGroup>

        {!loading ? (
          <CustomerDataAnalyticsTable {...{ customerDataRecords, currentPage, changePage, total, t }} />
        ) : (
          <Loading size={30} />
        )}
      </CCard>
    </CCol>
  );
};

export default CustomerDataAnalytics;

import flatten from 'flat';
import en from './en';
import de from './de';

const messages = {
  en,
  de,
};

// https://stackoverflow.com/questions/45783677/react-intl-accessing-nested-messages
Object.entries(messages).forEach(([locale, messageDictionary]) => {
  messages[locale] = flatten(messageDictionary);
});

export default messages;

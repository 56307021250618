/* eslint-disable consistent-return */
import React from 'react';
import get from 'lodash/get';
import routePaths from 'router/route-paths';
import { useHistory } from 'react-router-dom';
import useLogout from './useLogout';

const useAuthFormSubmit = (auth) => {
  const logout = useLogout();
  const history = useHistory();

  return React.useCallback(
    async (variables, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        const data = await auth(variables);
        const roles = get(data, 'user.roles', []);
        if (data && !roles.includes('admin')) {
          logout();
        } else if (roles.includes('admin')) {
          return data;
        }
      } catch (error) {
        if (error.message === 'AUTH_OTP_REQUIRED_ERROR') history.push(routePaths.loginTwoFA, { user: variables });
        setErrors({ form: error.message });
      } finally {
        setSubmitting(false);
      }
    },
    [auth, history, logout],
  );
};

export default useAuthFormSubmit;

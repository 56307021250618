import gql from 'graphql-tag';

export const CUSTOMER_FRAGMENT = gql`
  fragment hypoNoeApiRequestCustomer on Customer {
    first_name {
      parameter
      value
    }
    surname {
      parameter
      value
    }
    date_of_birth {
      parameter
      value
    }
    dependent_people {
      parameter
      value
    }
    has_kfz {
      parameter
      value
    }
    phone {
      parameter
      value
    }
    email {
      parameter
      value
    }
    branch {
      parameter
      value
    }
    market_value {
      parameter
      value
    }
    earnings {
      parameter
      value
    }
    monthly_income {
      parameter
      value
    }
    monthly_payments_count {
      parameter
      value
    }
    rental_and_lease_income {
      parameter
      value
    }
    family_allowance {
      parameter
      value
    }
    living_area_in_square_meters {
      parameter
      value
    }
    rental_expenses {
      parameter
      value
    }
    utilities_costs {
      parameter
      value
    }
    other_house_expenses {
      parameter
      value
    }
    number_of_dependents {
      parameter
      value
    }
    alimony {
      parameter
      value
    }
    household_costs {
      parameter
      value
    }
    vehicle_costs {
      parameter
      value
    }
    other_transportation_costs {
      parameter
      value
    }
    loan_installment_consisting {
      parameter
      value
    }
    other_current_income {
      parameter
      value
    }
  }
`;

const HYPO_NOE_API_REQUEST_PARAMS = gql`
  fragment hypoNoeApiRequestParams on HypoNoeApiRequestParams {
    project_name {
      parameter
      value
    }
    project_type {
      parameter
      value
    }
    purchase_price {
      parameter
      value
    }
    is_internal {
      parameter
      value
    }
    has_mediator {
      parameter
      value
    }
    has_broker {
      parameter
      value
    }
    own_funds {
      parameter
      value
    }
    own_funds_brought_in {
      parameter
      value
    }
    own_funds_surrogate {
      parameter
      value
    }
    comment {
      parameter
      value
    }
    agency_fee_satz {
      parameter
      value
    }
    agency_fee_fixed_value {
      parameter
      value
    }
    real_estate_transfer_tax {
      parameter
      value
    }
    building_costs {
      parameter
      value
    }
    finishing_costs {
      parameter
      value
    }
    contract_establishment_fee_satz {
      parameter
      value
    }
    contract_establishment_fee_fixed_value {
      parameter
      value
    }
    property_registration_fee {
      parameter
      value
    }
    broker_fee {
      parameter
      value
    }
    housing_promotion {
      parameter
      value
    }
    duration_in_months {
      parameter
      value
    }
    customers {
      ...hypoNoeApiRequestCustomer
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export default HYPO_NOE_API_REQUEST_PARAMS;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { CCard, CCardHeader, CCardBody, CCol } from '@coreui/react';
import TextInput from 'components/inputs/TextInput';
import Button from 'components/common/Button';
import Editor from 'components/common/Editor';
import { useIntl } from 'react-intl';
import { routePatterns } from 'router/route-paths';
import s from './Feedback.module.scss';

const FeedbackForm = ({ initialValues, onSubmit, message }) => {
  const { formatMessage: t } = useIntl();
  return (
    <div>
      <Formik {...{ initialValues, onSubmit }}>
        {({ isSubmitting, touched, errors }) => (
          <CCol xs="12" md="9" lg="7" className="ml-auto mr-auto">
            <CCard>
              <CCardHeader>{t({ id: 'messages.feedback' })}</CCardHeader>
              <CCardBody>
                <Form>
                  <Field
                    name="email"
                    component={TextInput}
                    required
                    max={100}
                    label={t({ id: 'auth.email' })}
                    disabled
                    invalid={!!(touched.email && errors.email)}
                  />
                  <Field
                    name="name"
                    component={TextInput}
                    required
                    max={100}
                    label={t({ id: 'auth.name' })}
                    disabled
                    invalid={!!(touched.name && errors.name)}
                  />
                  <label>{t({ id: 'messages.userMessage' })}</label>
                  <p className={s.message}>{message}</p>
                  <Field name="message" component={Editor} />
                  <div className={s.buttonsWrapper}>
                    <Link to={routePatterns.messages.stringify({ page: 1 })}>
                      <Button color="transparent" type="submit" disabled={isSubmitting}>
                        {t({ id: 'buttons.cancel' })}
                      </Button>
                    </Link>
                    <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                      {t({ id: 'app.send' })}
                    </Button>
                  </div>
                </Form>
              </CCardBody>
            </CCard>
          </CCol>
        )}
      </Formik>
    </div>
  );
};

export default FeedbackForm;

import React, { Suspense } from 'react';
import { CContainer } from '@coreui/react';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const Content = ({ content }) => {
  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>{content}</Suspense>
      </CContainer>
    </main>
  );
};

export default React.memo(Content);

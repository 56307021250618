import React from 'react';
import { Redirect } from 'react-router-dom';
import useLogout from 'hooks/auth/useLogout';
import LoadingOverlay from '../components/common/LoadingOverlay';
import routePaths from '../router/route-paths';

export default function Logout() {
  const logout = useLogout();
  React.useEffect(() => logout(), [logout]);

  return (
    <>
      <LoadingOverlay />
      <Redirect to={routePaths.login} />
    </>
  );
}

import { CCard, CCardBody, CCardHeader, CCol, CDataTable, CFormGroup, CInput, CLabel } from '@coreui/react';
import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import Pagination from 'components/common/Pagination';
import useCO2SavingsByProjects from 'hooks/useCO2SavingsByProjects';
import useFilterValue from 'hooks/useFilterValue';
import usePagination from 'hooks/usePagination';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';

const CO2SavingsByProjectsTable = ({ co2Calculations = [], count, currentPage, changePage, t }) => {
  const tableHead = useMemo(
    () => [
      { key: 'name', label: t({ id: 'projects.name' }) },
      { key: 'email', label: t({ id: 'auth.email' }) },
      { key: 'username', label: t({ id: 'co2_report.username' }) },
      { key: 'energyGhgBalanceBefore', label: t({ id: 'co2_report.energy_ghg_balance_before' }) },
      {
        key: 'controlled_living_space_ventilation',
        label: t({ id: 'co2_report.controlled_living_space_ventilation' }),
      },
      { key: 'heating_system', label: t({ id: 'co2_report.heating_system' }) },
      { key: 'insulation_basement_ceiling', label: t({ id: 'co2_report.insulation_basement_ceiling' }) },
      { key: 'insulation_facade', label: t({ id: 'co2_report.insulation_facade' }) },
      { key: 'insulation_top_ceiling', label: t({ id: 'co2_report.insulation_top_ceiling' }) },
      { key: 'new_windows', label: t({ id: 'co2_report.new_windows' }) },
      { key: 'solar_power_system', label: t({ id: 'co2_report.solar_power_system' }) },
      { key: 'energyGhgBalanceAfter', label: t({ id: 'co2_report.energy_ghg_balance_after' }) },
    ],
    [t],
  );

  const items = useMemo(
    () =>
      co2Calculations.map(({ project, co2_emissions_after, co2_emissions_before }) => {
        const { user, name } = project;
        const { energy_ghg_balance_per_year: energyGhgBalanceBefore } = co2_emissions_before.result;
        const { co2_savings, energy_ghg_balance_per_year: energyGhgBalanceAfter } = co2_emissions_after.result;

        const email = get(user, 'emails[0].address', '-');
        const firstName = get(user, 'profile.firstName', '-');
        const lastName = get(user, 'profile.lastName', '-');
        const username = `${firstName} ${lastName}`;

        const values = Object.entries(co2_savings).reduce((acc, [key, value]) => {
          const v = !Number.isFinite(value) ? '-' : value;
          return { ...acc, email, username, [key]: v };
        }, {});

        return { name, energyGhgBalanceBefore, energyGhgBalanceAfter, ...values };
      }),
    [co2Calculations],
  );

  return (
    <>
      <CDataTable responsive hover items={items} fields={tableHead} size="sm" />
      <Pagination {...{ changePage, currentPage, total: count }} />
    </>
  );
};

const CO2SavingsByProjects = () => {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'co2SavingsByProjects' });
  const { filter, handleChange, debouncedFilterValue } = useFilterValue({ changePage });
  const { co2Calculations, count, loading } = useCO2SavingsByProjects({ currentPage, filter: debouncedFilterValue });

  return (
    <CCol>
      <CCard>
        <CCardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4>{t({ id: 'page_titles.projects' })}</h4>
          <Link to={routePaths.co2SavingsReport}>
            <Button>{t({ id: 'co2_report.back_to_co2_report' })}</Button>
          </Link>
        </CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
            <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
          </CFormGroup>
          {!loading ? (
            <CO2SavingsByProjectsTable {...{ count, co2Calculations, currentPage, changePage, t }} />
          ) : (
            <Loading size={30} />
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default CO2SavingsByProjects;

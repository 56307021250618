import { useEffect } from 'react';
import { connect } from 'formik';
import { animateScroll } from 'react-scroll';

const ErrorFocus = ({ formik }) => {
  useEffect(() => {
    const { isSubmitting, isValidating, errors } = formik;
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[id="${keys[0]}"], [name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        const coordinates = errorElement.getBoundingClientRect();
        animateScroll.scrollTo(coordinates.top + window.pageYOffset - 300);
      }
    }
  }, [formik]);
  return null;
};

export default connect(ErrorFocus);

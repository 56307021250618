import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import useLogout from 'hooks/auth/useLogout';
import { clearIntervalAsync } from 'set-interval-async';
import { setIntervalAsync } from 'set-interval-async/dynamic';

function saveFile({ blob, fileName }) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}-${new Date().getTime()}.xlsx`;
  link.click();
}

const useFetchXlsxFile = ({ url, fileName }) => {
  const logout = useLogout();
  const [fetching, setFetching] = useState(false);

  const executeRequest = useCallback(async () => {
    setFetching(true);
    let requestId = '';
    let iteration = 0;
    const intervalId = setIntervalAsync(async () => {
      iteration += 1;
      try {
        if (iteration > 150) {
          // 5 min
          throw new Error('Iteration limit');
        }
        const controller = new AbortController();
        const { signal } = controller;
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, 20000);
        const response = await fetch(`${url}${requestId ? `?id=${requestId}` : ''}`, { signal });
        clearTimeout(timeoutId);
        if (response.status === 401) {
          clearIntervalAsync(intervalId);
          logout();
        }
        const blob = await response.blob();
        const res = await blob.text();
        const responseRequestId = Number.isFinite(+res) ? +res : false;

        if (responseRequestId) {
          requestId = responseRequestId;
        }
        if (!responseRequestId && res !== 'loading') {
          saveFile({ blob, fileName });
          setFetching(false);
          clearIntervalAsync(intervalId);
        }
      } catch (error) {
        if (error.name === 'AbortError') return;
        setFetching(false);
        toast.error(error.message);
        clearIntervalAsync(intervalId);
      }
    }, 2000);
  }, [fileName, logout, url]);
  return [executeRequest, fetching];
};

export default useFetchXlsxFile;

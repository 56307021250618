import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';

const LOAN_QUERY = gql`
  query adminLoan {
    adminLoan {
      variableInterestRate {
        euribor_6m
      }
      fixedInterestRate {
        key
        value
      }
      loanCosts
      loanCostsFix
      interestRates {
        sk1
        period
        interest_rate
      }
      sk1Factor
      ownFundsRatio
      maxLoanRateRatio
      interestBandwith
      last_updated_at
      last_updated_by {
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

const useAdminLoan = () => {
  const { data, loading } = useQuery(LOAN_QUERY);
  const loan = get(data, 'adminLoan', null);

  return [loan, loading];
};

export default useAdminLoan;

import React, { useCallback, useMemo } from 'react';
import LoadingOverlay from 'components/common/LoadingOverlay';
import QRCode from 'qrcode';
import { Formik, Form, Field } from 'formik';
import { CContainer, CRow, CCol, CCardGroup, CCard, CCardBody } from '@coreui/react';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import OtpInput from 'components/inputs/OtpInput';
import useInit2FA from 'hooks/auth/useInit2FA';
import useEnable2FA from 'hooks/auth/useEnable2FA';
import { useHistory } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import useLogout from 'hooks/auth/useLogout';
import ErrorMessage from 'components/common/ErrorMessage';
import settings from 'config/settings';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import Button from 'components/common/Button';

const useEnable2FAHandler = () => {
  const enable2FA = useEnable2FA();
  const history = useHistory();

  const handleVerify = useCallback(
    async (otp, { setSubmitting, resetForm, setErrors }) => {
      try {
        await enable2FA(otp);
        history.push(routePatterns.messages.stringify({ page: 1 }));
        resetForm();
      } catch (error) {
        setErrors({ form: error.message });
      } finally {
        setSubmitting(false);
      }
    },
    [enable2FA, history],
  );

  return handleVerify;
};

const initialValue = {
  otp: '',
};

const schema = yup.object().shape({
  otp: yup
    .string()
    .required(<FormattedMessage id="errors.fill_field" />)
    .typeError(<FormattedMessage id="errors.fill_field" />),
});

const InitTwoFA = () => {
  const [qrCode, loading] = useInit2FA();
  const onSubmit = useEnable2FAHandler();
  const logout = useLogout();

  const onCancel = useCallback(() => logout(), [logout]);

  const qrCodeUrl = useMemo(() => {
    let url = '';
    if (!qrCode) return url;
    QRCode.toDataURL(qrCode, (err, image_data) => {
      url = image_data;
    });
    return url;
  }, [qrCode]);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <Formik initialValues={initialValue} validationSchema={schema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <>
          <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
              <CRow className="justify-content-center mt-3">
                <CCol md="10">
                  <CCardGroup className="d-flex flex-wrap">
                    <CCard className="p-4 text-center">
                      <CCardBody>
                        <Form>
                          <div>
                            <h1>
                              <FormattedMessage id="auth.activate_authenticator" />
                            </h1>
                            <div style={{ margin: '15px' }}>
                              <img src={qrCodeUrl} alt="qrcode" />
                            </div>
                          </div>
                          <Field
                            name="otp"
                            component={OtpInput}
                            numInputs={6}
                            separator="-"
                            color="white"
                            required
                            label={<FormattedMessage id="auth.verification_code" />}
                          />
                          <CRow>
                            <CCol xs="12">
                              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <Button onClick={onCancel} disabled={isSubmitting} color="secondary">
                                  <FormattedMessage id="buttons.cancel" />
                                </Button>
                                <Button disabled={isSubmitting} loading={isSubmitting} color="primary" type="submit">
                                  <FormattedMessage id="buttons.submit" />
                                </Button>
                              </div>
                              <ErrorMessage message={errors.form} />
                            </CCol>
                          </CRow>
                        </Form>
                      </CCardBody>
                    </CCard>
                    <CCard className="text-black bg-light" style={{ textAlign: 'left' }}>
                      <CCardBody>
                        <h2>
                          <FormattedMessage id="init2FA.configure_code_generator" />
                        </h2>
                        <span style={{ whiteSpace: 'pre-line' }}>
                          <FormattedHTMLMessage
                            id="init2FA.steps"
                            values={{
                              playMarketLink: settings.googleAuthenticatorPlayMarket,
                              appStoreLink: settings.googleAuthenticatorAppStore,
                            }}
                          />
                        </span>
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </>
      )}
    </Formik>
  );
};

export default InitTwoFA;

import { SK1, PERIODS } from 'constants/constants';
import * as yup from 'yup';
import { __ } from 'utils/helpers';

export default yup.object().shape({
  euribor_6m: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  fixedInterestRate_10: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  fixedInterestRate_15: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  fixedInterestRate_20: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  ...SK1.reduce((result, sk) => {
    const interestRates = PERIODS.reduce((res, period) => {
      return {
        ...res,
        [`interestRate_${sk}_${period}`]: yup
          .number()
          .required(__('errors.required'))
          .typeError(__('errors.required')),
      };
    }, {});
    return { ...result, ...interestRates };
  }, []),
  loanCosts: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  sk1Factor: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  ownFundsRatio: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  maxLoanRateRatio: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  interestBandwith: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
});

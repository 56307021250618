import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const RECALCULATE_ALL_PROJECTS = gql`
  mutation recalculateAllProjects {
    recalculateAllProjects {
      success
    }
  }
`;

const useRecalculateAllProjects = () => {
  const [recalculateAllProjects] = useMutation(RECALCULATE_ALL_PROJECTS);

  const handleRecalculate = useCallback(async () => {
    try {
      await recalculateAllProjects();
    } catch (error) {
      const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
      e.raw = error;
      throw e;
    }
  }, [recalculateAllProjects]);

  return handleRecalculate;
};

export default useRecalculateAllProjects;

import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_LOAN_CONSULTANTS } from './useGetLoanConsultants';

const DELETE_LOAN_CONSULTANT_MUTATION = gql`
  mutation deleteLoanConsultant($email: String!) {
    deleteLoanConsultant(email: $email) {
      success
    }
  }
`;

const useDeleteLoanConsultant = () => {
  const [deleteLoanConsultantMutation] = useMutation(DELETE_LOAN_CONSULTANT_MUTATION);

  const handleDelete = useCallback(
    async (variables) => {
      try {
        await deleteLoanConsultantMutation({
          variables,
          refetchQueries: [{ query: GET_LOAN_CONSULTANTS }],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [deleteLoanConsultantMutation],
  );
  return handleDelete;
};

export default useDeleteLoanConsultant;

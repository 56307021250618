import React, { useMemo } from 'react';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavItem,
} from '@coreui/react';
import { useSidebar } from 'contexts/AppContext';
import { zIndexSidebar } from 'styles/variables.scss';
import { useIntl } from 'react-intl';
import { routePatterns } from 'router/route-paths';
import sidebarItems from './sidebarItems';

const Sidebar = () => {
  const { showSidebar } = useSidebar();
  const { formatMessage: t } = useIntl();

  const localizedSidebarItems = useMemo(
    () =>
      sidebarItems.filter(({ hide }) => !hide).map(({ name, hide, ...rest }) => ({ name: t({ id: name }), ...rest })),
    [t],
  );

  return (
    <CSidebar show={showSidebar} style={{ zIndex: zIndexSidebar }}>
      <CSidebarBrand className="d-md-down-none" to={routePatterns.projects.stringify({ page: 1 })} />
      <CSidebarNav>
        <CCreateElement items={localizedSidebarItems} components={{ CSidebarNavItem }} />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(Sidebar);

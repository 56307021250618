import React from 'react';
import LoadingOverlay from 'components/common/LoadingOverlay';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';
import useUser from '../hooks/useUser';

const RootRouter = () => {
  const [user, loading] = useUser();

  if (loading && !user) {
    return <LoadingOverlay />;
  }

  if (user && !user.roles.includes('admin')) {
    return <AuthRouter />;
  }

  return <>{user ? <AppRouter /> : <AuthRouter />}</>;
};

export default RootRouter;

import React, { useMemo } from 'react';
import { CDataTable, CButton, CBadge } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import { cilBan, cilEnvelopeClosed } from '@coreui/icons';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'utils/helpers';
import Pagination from 'components/common/Pagination';
import s from './FinancingRequestsTable.module.scss';

const NoItemsView = () => (
  <div className={s.noItemsView}>
    <div className={s.text}>
      <FormattedMessage id="financing_requests.empty" />
    </div>
    <CIcon content={cilBan} size="2xl" />
  </div>
);

const FinancingRequestsTable = ({ t, financingRequests, showModal, changePage, currentPage, total }) => {
  const fields = useMemo(
    () => [
      { key: 'projectName', label: t({ id: 'financing_requests.project_name' }) },
      { key: 'email', label: t({ id: 'financing_requests.email' }) },
      { key: 'user', label: t({ id: 'financing_requests.user' }) },
      { key: 'created_at', label: t({ id: 'financing_requests.created_at' }) },
      { key: 'message', label: t({ id: 'financing_requests.message' }) },
    ],
    [t],
  );

  const items = useMemo(
    () =>
      financingRequests.map(({ _id, user: { email, firstName, lastName }, created_at, ...rest }) => ({
        _id,
        email,
        user: `${firstName} ${lastName}`,
        created_at: formatDate(created_at),
        ...rest,
      })),
    [financingRequests],
  );

  return (
    <>
      <CDataTable
        noItemsViewSlot={<NoItemsView />}
        items={items}
        fields={fields}
        size="sm"
        itemsPerPage={COUNT_TABLE_RECORDS}
        scopedSlots={{
          projectName: ({ projectName, archived }) => (
            <td>
              <span>
                {projectName}{' '}
                {archived && (
                  <CBadge color="warning">
                    <FormattedMessage id="projects.archived" />
                  </CBadge>
                )}
              </span>
            </td>
          ),
          message: ({ message }) => (
            <td>
              <CButton onClick={() => showModal(message)} style={{ backgroundColor: '#ddd', borderRadius: '5px' }}>
                <CIcon content={cilEnvelopeClosed} style={{ marginTop: 0 }} />
              </CButton>
            </td>
          ),
          email: ({ email }) => (
            <td>
              <a href={`mailto:${email}`}>{email}</a>
            </td>
          ),
        }}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default FinancingRequestsTable;

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { CCard, CCardHeader, CCol, CFormGroup, CLabel, CInput } from '@coreui/react';
import useGetMessages from 'hooks/contact/useGetMessages';
import { toast } from 'react-toastify';
import useMarkMessageRead from 'hooks/contact/useMarkMessageRead';
import MessagesTable from 'components/contact/MessagesTable';
import { useIntl } from 'react-intl';
import Loading from 'components/common/Loading';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';
import s from './Messages.module.scss';

const useOnRowClickHandler = (page) => {
  const history = useHistory();
  const markMessageRead = useMarkMessageRead();
  const handleClick = useCallback(
    async (message) => {
      try {
        history.push(routePatterns.message.stringify({ id: message.id }), { messageDetails: { ...message, page } });
        await markMessageRead(message.id, page);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [history, markMessageRead, page],
  );
  return handleClick;
};

export default function Messages() {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'messages' });
  const { handleChange, debouncedFilterValue, filter } = useFilterValue({ changePage });
  const { messages, total, loading } = useGetMessages({ currentPage, filter: debouncedFilterValue });
  const onRowClick = useOnRowClickHandler(currentPage);

  return (
    <CCol xs="12" lg="12">
      <CCard>
        <CCardHeader className={s.header}>
          <h4>{t({ id: 'page_titles.messages' })}</h4>
        </CCardHeader>
        <CFormGroup className={s.filter}>
          <CLabel htmlFor="filter">{t({ id: 'messages.filter' })}</CLabel>
          <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
        </CFormGroup>

        {!loading ? (
          <MessagesTable {...{ messages, onRowClick, total, currentPage, changePage }} />
        ) : (
          <Loading size={30} />
        )}
      </CCard>
    </CCol>
  );
}

import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const LOAN_MUTATATION = gql`
  mutation editLoan($formData: AdminLoanInput!) {
    editLoan(formData: $formData)
  }
`;

const useEditLoan = () => {
  const [loanMutation] = useMutation(LOAN_MUTATATION);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        await loanMutation({ variables: { formData }, awaitRefetchQueries: true, refetchQueries: ['adminLoan'] });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [loanMutation],
  );
  return handleSubmit;
};

export default useEditLoan;

import { COUNT_TABLE_RECORDS } from 'constants/constants';
import React from 'react';
import CPagination from 'rc-pagination';
import s from './Pagination.module.scss';

const Pagination = ({ changePage, currentPage, total }) => {
  return (
    <CPagination
      prevIcon={<div className={s.arrowBack} />}
      nextIcon={<div className={s.arrowForward} />}
      pageSize={COUNT_TABLE_RECORDS}
      onChange={changePage}
      current={currentPage}
      total={total}
    />
  );
};

export default Pagination;

import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import { MESSAGES_QUERY } from './useGetMessages';

const SEND_FEEDBACK_MUTATION = gql`
  mutation sendFeedback($email: String!, $message: String!, $messageId: ID!) {
    sendFeedback(email: $email, message: $message, messageId: $messageId)
  }
`;

const useSendFeedback = () => {
  const [sendFeedbackMutation] = useMutation(SEND_FEEDBACK_MUTATION);

  const handleSubmit = useCallback(
    async (variables, page) => {
      try {
        await sendFeedbackMutation({
          variables,
          refetchQueries: [
            {
              query: MESSAGES_QUERY,
              variables: { offset: COUNT_TABLE_RECORDS * (page - 1), limit: COUNT_TABLE_RECORDS },
            },
          ],
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [sendFeedbackMutation],
  );

  return handleSubmit;
};

export default useSendFeedback;

import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import get from 'lodash/get';
import NotFound from 'pages/NotFound';
import Messages from 'pages/Messages';
import Projects from 'pages/Projects';
import Loan from 'pages/Loan';
import Feedback from 'pages/Feedback';
import AdminsPage from 'pages/AdminsPage';
import AppLayout from 'components/layouts/AppLayout';
import Logout from 'pages/Logout';
import InitTwoFA from 'pages/InitTwoFA';
import useUser from 'hooks/useUser';
import Notifications from 'pages/Notifications';
import FinancingRequests from 'pages/FinancingRequests';
import LoanConsultantsPage from 'pages/LoanConsultantsPage';
import CustomerDataAnalytics from 'pages/CustomerDataAnalytics';
import CO2SavingsReport from 'pages/CO2SavingsReport';
import CO2SavingsByProjects from 'pages/CO2SavingsByProjects';
import HypoNoeApiRequestsReport from 'pages/HypoNoeApiRequestsReport';
import routePaths from './route-paths';

const routes = [
  {
    path: routePaths.logout,
    component: Logout,
  },
  {
    path: routePaths.messages,
    component: Messages,
  },
  {
    path: routePaths.loan,
    component: Loan,
  },
  {
    path: routePaths.message,
    component: Feedback,
  },
  {
    path: routePaths.admins,
    component: AdminsPage,
  },
  {
    path: routePaths.financingRequests,
    component: FinancingRequests,
  },
  {
    path: routePaths.projects,
    component: Projects,
  },
  {
    path: routePaths.notifications,
    component: Notifications,
  },
  {
    path: routePaths.loanConsultants,
    component: LoanConsultantsPage,
  },
  {
    path: routePaths.customerDataAnalytics,
    component: CustomerDataAnalytics,
  },
  {
    path: routePaths.co2SavingsReport,
    component: CO2SavingsReport,
  },
  {
    path: routePaths.co2SavingsByProjects,
    component: CO2SavingsByProjects,
  },
  {
    path: routePaths.hypoNoeApiRequestsReport,
    component: HypoNoeApiRequestsReport,
  },
];

const AppRouter = () => {
  const [me] = useUser();
  const is2FAEnabled = get(me, 'is2FAEnabled', null);
  return (
    <>
      <Switch>
        {!is2FAEnabled && (
          <Route path={routePaths.initTwoFA} exact>
            <InitTwoFA />
          </Route>
        )}
        {!is2FAEnabled && <Redirect to={routePaths.initTwoFA} />}
        <AppLayout>
          <Switch>
            {routes.map(({ path, component, ...rest }) => (
              <Route key={path} path={path} component={component} exact {...rest} />
            ))}
            <Route path="*" component={NotFound} />
          </Switch>
        </AppLayout>
      </Switch>
    </>
  );
};

export default AppRouter;

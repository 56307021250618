import React, { useMemo } from 'react';
import useGetNotifications from 'hooks/useGetNotifications';
import { CDataTable, CCol, CCard, CCardHeader, CCardBody } from '@coreui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import CIcon from '@coreui/icons-react';
import { cilBan } from '@coreui/icons';
import { formatDate } from 'utils/helpers';
import LoadingOverlay from 'components/common/LoadingOverlay';
import s from './Notifications.module.scss';

const NoItemsView = () => (
  <div className={s.noItemsView}>
    <div className={s.text}>
      <FormattedMessage id="notifications.empty" />
    </div>
    <CIcon content={cilBan} size="2xl" />
  </div>
);

const Notifications = () => {
  const [notifications, loading] = useGetNotifications();
  const { formatMessage: t } = useIntl();

  const fields = useMemo(
    () => [
      { key: 'message', label: t({ id: 'notifications.message' }) },
      { key: 'date', label: t({ id: 'notifications.date' }) },
    ],
    [t],
  );

  const items = useMemo(
    () => notifications.map(({ message, created_at }) => ({ date: formatDate(created_at), message })),
    [notifications],
  );

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>{t({ id: 'page_titles.notifications' })}</CCardHeader>
          <CCardBody className={s.cardBody}>
            <CDataTable noItemsViewSlot={<NoItemsView />} items={items} fields={fields} size="sm" />
          </CCardBody>
        </CCard>
      </CCol>
    </>
  );
};

export default Notifications;

import React from 'react';
import { CCard, CCardHeader, CCol, CFormGroup, CLabel, CInput } from '@coreui/react';
import useGetProjects from 'hooks/projects/useGetProjects';
import ProjectsTable from 'components/projects/ProjectsTable';
import { useIntl } from 'react-intl';
import Loading from 'components/common/Loading';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';
import Button from 'components/common/Button';
import useFetchXlsxFile from 'hooks/useFetchXlsxFile';
import s from './Projects.module.scss';

const xlsxUsersReportUrl = '/_pdf/xls_report_users';
const xlsxProjectsReportUrl = '/_pdf/xls_projects_export';

export default function Projects() {
  const { formatMessage: t } = useIntl();
  const [fetchUsersReport, isFetchingUsersReport] = useFetchXlsxFile({
    url: xlsxUsersReportUrl,
    fileName: 'UsersReport',
  });
  const [fetchProjectsReport, isFetchingProjectsReport] = useFetchXlsxFile({
    url: xlsxProjectsReportUrl,
    fileName: 'ProjectsReport',
  });
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'projects' });
  const { handleChange, debouncedFilterValue, filter } = useFilterValue({ changePage });
  const { projects, total, loading } = useGetProjects({ currentPage, filter: debouncedFilterValue });

  return (
    <CCol xs="12" lg="12">
      <CCard>
        <CCardHeader className={s.header}>
          <h4>{t({ id: 'page_titles.projects' })}</h4>
          <div className={s.buttonsWrapper}>
            <Button
              disabled={isFetchingUsersReport}
              loading={isFetchingUsersReport}
              onClick={fetchUsersReport}
              color="primary"
            >
              {t({ id: 'projects.report_about_registered_users' })}
            </Button>
            <Button
              disabled={isFetchingProjectsReport}
              loading={isFetchingProjectsReport}
              onClick={fetchProjectsReport}
              color="primary"
            >
              {t({ id: 'projects.xlsx_export' })}
            </Button>
          </div>
        </CCardHeader>

        <CFormGroup className={s.filter}>
          <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
          <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
        </CFormGroup>

        {!loading ? <ProjectsTable {...{ projects, total, currentPage, changePage, t }} /> : <Loading size={30} />}
      </CCard>
    </CCol>
  );
}

import React from 'react';
import { Content, Sidebar, Footer, Header } from 'components/layout/index';

export default function AppLayout({ children }) {
  return (
    <>
      <div className="c-app c-default-layout">
        <Sidebar />
        <div className="c-wrapper">
          <Header />
          <div className="c-body">
            <Content content={children} />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

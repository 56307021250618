import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { MESSAGES_QUERY } from './useGetMessages';

const MARK_MESSAGE_READ = gql`
  mutation markMessageRead($messageId: ID!) {
    markMessageRead(messageId: $messageId)
  }
`;

const useMarkMessageRead = () => {
  const [markMessageRead] = useMutation(MARK_MESSAGE_READ);
  const handleChange = useCallback(
    async (messageId, page) => {
      try {
        await markMessageRead({
          variables: { messageId },
          refetchQueries: [{ query: MESSAGES_QUERY, variables: { page, offset: 20 * (page - 1), limit: 20 } }],
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [markMessageRead],
  );
  return handleChange;
};

export default useMarkMessageRead;

import UrlPattern from 'url-pattern';

const routePaths = {
  login: '/login',
  logout: '/logout',
  messages: '/messages/:page',
  message: '/message/:id',
  loan: '/loan',
  admins: '/admins',
  loanConsultants: '/loan-consultants',
  financingRequests: '/financing-requests/:page',
  initTwoFA: '/init-two-fa',
  projects: '/projects/:page',
  customerDataAnalytics: '/customer-data-analytics/:page',
  loginTwoFA: '/login-two-fa',
  notifications: '/notifications',
  co2SavingsReport: '/co2-savings-report',
  co2SavingsByProjects: '/co2-savings-by-projects/:page',
  hypoNoeApiRequestsReport: '/hypo-noe-api-requests-report/:page',
};

export const routePatterns = Object.entries(routePaths).reduce(
  (p, [key, pattern]) => ({ ...p, [key]: new UrlPattern(pattern) }),
  {},
);

routePaths.default = routePatterns.projects.stringify({ page: 1 });

export default routePaths;

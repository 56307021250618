import React, { useCallback } from 'react';
import ReactOtpInput from 'react-otp-input';
import get from 'lodash/get';
import { CFormGroup, CLabel, CInvalidFeedback } from '@coreui/react';
import s from './OtpInput.module.scss';

const OtpInput = ({ field, form, label, numInputs = 6, separator = '-' }) => {
  const { name, onBlur } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  const getClassNameForInput = get(touched, name) && get(errors, name) ? 'form-control is-invalid' : 'form-control';

  const handleChange = useCallback(
    (v) => {
      setFieldValue(name, v);
    },
    [name, setFieldValue],
  );

  const handleOnBlur = useCallback(() => {
    onBlur();
    setFieldTouched(name, true);
  }, [name, onBlur, setFieldTouched]);

  return (
    <div>
      <CFormGroup>
        <CLabel htmlFor={name}>{label}</CLabel>
        <ReactOtpInput
          {...field}
          onBlur={handleOnBlur}
          numInputs={numInputs}
          separator={<span>{separator}</span>}
          name={name}
          className={getClassNameForInput}
          onChange={handleChange}
          containerStyle={s.container}
          inputStyle={s.input}
          focusStyle={s.focusInput}
          isInputNum
          shouldAutoFocus
        />
        {get(touched, name) && get(errors, name) && (
          <CInvalidFeedback className="help-block">{errors[name]}</CInvalidFeedback>
        )}
      </CFormGroup>
    </div>
  );
};

export default OtpInput;

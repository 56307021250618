import { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';

const useFilterValue = ({ changePage }) => {
  const [filter, setFilter] = useState('');
  const [debouncedFilterValue] = useDebounce(filter, 1000);

  const handleChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  useEffect(() => {
    if (filter && debouncedFilterValue === filter) changePage(1);
  }, [changePage, debouncedFilterValue, filter]);

  return { filter, handleChange, debouncedFilterValue };
};

export default useFilterValue;

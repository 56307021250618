import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_LOAN_CONSULTANTS } from './useGetLoanConsultants';

const ADD_LOAN_CONSULTANT_MUTATION = gql`
  mutation addLoanConsultant($input: ProfileInput!) {
    addLoanConsultant(input: $input) {
      success
    }
  }
`;

const useAddLoadConsultant = () => {
  const [addLoanConsultantMutation] = useMutation(ADD_LOAN_CONSULTANT_MUTATION);

  const handleSubmit = useCallback(
    async (variables) => {
      try {
        await addLoanConsultantMutation({
          variables: { input: { ...variables } },
          refetchQueries: [{ query: GET_LOAN_CONSULTANTS }],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [addLoanConsultantMutation],
  );

  return handleSubmit;
};

export default useAddLoadConsultant;

import React from 'react';
import ReactQuill from 'react-quill';
import { useIntl } from 'react-intl';
import './Editor.scss';

const Editor = ({ field }) => {
  const { value, name, onChange } = field;
  const { formatMessage } = useIntl();

  return (
    <div className="editor-container">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange(name)}
        placeholder={formatMessage({ id: 'messages.placeholder_for_editor' })}
        modules={Editor.modules}
        formats={Editor.formats}
      />
    </div>
  );
};

Editor.modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
};

export default Editor;

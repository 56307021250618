import React from 'react';
import { FormattedMessage } from 'react-intl';

const FormattedHTMLMessage = ({ id, defaultMessage = ' ', values }) => {
  return (
    <FormattedMessage
      id={id}
      defaultMessage={defaultMessage}
      values={{
        p: (...chunks) => <p key={chunks}>{chunks}</p>,
        ul: (...chunks) => <ul key={chunks}>{chunks}</ul>,
        ol: (...chunks) => <ol key={chunks}>{chunks}</ol>,
        li: (chunks) => {
          return <li key={chunks}>{chunks}</li>;
        },
        a: (chunks) => {
          const chunk = chunks[0];
          const href = chunk.substr(5, chunk.indexOf(' ') - 5);
          const label = chunk.substr(chunk.indexOf(' ') + 1);
          return (
            <a href={href} key={chunks} target="_blank" rel="noopener noreferrer">
              {label}
            </a>
          );
        },
        ...values,
      }}
    />
  );
};

export default FormattedHTMLMessage;

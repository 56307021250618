/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState } from 'react';
import NumberInput from 'components/inputs/NumberInput';
import Button from 'components/common/Button';
import { Formik, Field, Form } from 'formik';
import { useIntl } from 'react-intl';
import { CCard, CCardHeader, CCardBody, CCol } from '@coreui/react';
import ScrollToError from 'components/common/ScrollToError';
import { SK1, PERIODS } from 'constants/constants';
import { formatDate } from 'utils/helpers';
import useRecalculateAllProjects from 'hooks/useRecalculateAllProjects';
import { toast } from 'react-toastify';
import s from './LoanVariablesForm.module.scss';
import loanFormSchema from './loanFormSchema';

const InterestRatesTable = ({ t }) => (
  <div style={{ position: 'relative', overflowX: 'auto' }}>
    <table style={{ minWidth: '550px' }}>
      <thead>
        <tr style={{ textAlign: 'center' }}>
          <th style={{ width: '20%' }}>{t({ id: 'loan.loan_period' })}</th>
          {PERIODS.map((period) => (
            <th key={period}>{t({ id: 'loan.years' }, { period })}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {SK1.map((sk) => (
          <tr key={sk}>
            <td style={{ textAlign: 'center' }}>{`${sk / 100} %`}</td>
            {PERIODS.map((period) => {
              return (
                <td className={s.interestRate} key={`interestRate_${sk}_${period}`} style={{ wordBreak: 'break-all' }}>
                  <Field
                    className={s.loanInput}
                    name={`interestRate_${sk}_${period}`}
                    component={NumberInput}
                    required
                    max={100}
                  />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

function LastModifiedBy({ t, lastUpdatedBy, lastUpdatedAt }) {
  const user = `${lastUpdatedBy.profile.firstName} ${lastUpdatedBy.profile.lastName}`;
  const time = formatDate(lastUpdatedAt);
  return <div className="text-right">{t({ id: 'loan.lastUpdated' }, { user, time })}</div>;
}

const useRecalculateAllProjectsHandler = () => {
  const recalculateAllProjects = useRecalculateAllProjects();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  const handleRecalculate = useCallback(async () => {
    setLoading(true);
    try {
      await recalculateAllProjects();
      toast.success(formatMessage({ id: 'loan.recalculation_success' }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [formatMessage, recalculateAllProjects]);

  return [handleRecalculate, loading];
};

const LoanVariablesForm = ({ initialValues, onSubmit, lastUpdatedAt, lastUpdatedBy }) => {
  const { formatMessage: t } = useIntl();
  const [onRecalculateClick, recalculateProjectsLoading] = useRecalculateAllProjectsHandler();

  return (
    <Formik initialValues={initialValues} validationSchema={loanFormSchema} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <CCol xs="12" xl="10" className="ml-auto mr-auto">
          <CCard>
            <ScrollToError />

            <CCardHeader className={s.headerWrapper}>
              <h4>{t({ id: 'page_titles.loan' })}</h4>
              <Button
                disabled={recalculateProjectsLoading}
                loading={recalculateProjectsLoading}
                onClick={onRecalculateClick}
              >
                {t({ id: 'loan.recalculate_all_projects' })}
              </Button>
            </CCardHeader>

            <CCardBody>
              {lastUpdatedBy ? <LastModifiedBy {...{ t, lastUpdatedAt, lastUpdatedBy }} /> : null}
              <Form className={s.loanForm}>
                <Field
                  name="euribor_6m"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.variable_interest_rate' })}
                />
                <Field
                  name="fixedInterestRate_10"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.fixed_interest_rates' }, { period: 10 })}
                />
                <Field
                  name="fixedInterestRate_15"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.fixed_interest_rates' }, { period: 15 })}
                />
                <Field
                  name="fixedInterestRate_20"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.fixed_interest_rates' }, { period: 20 })}
                />
                <InterestRatesTable {...{ t }} />

                <Field
                  name="loanCosts"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.loan_costs' })}
                />

                <Field
                  name="loanCostsFix"
                  component={NumberInput}
                  max={5000}
                  label={t({ id: 'loan.loan_costs_fix' })}
                />

                <Field
                  name="sk1Factor"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.sk1_factor' })}
                />
                <Field
                  name="ownFundsRatio"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.own_funds_ratio' })}
                />
                <Field
                  name="maxLoanRateRatio"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.max_loan_rate_ration' })}
                />
                <Field
                  name="interestBandwith"
                  component={NumberInput}
                  required
                  max={100}
                  label={t({ id: 'loan.interest_bandwith' })}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                    {t({ id: 'buttons.save' })}
                  </Button>
                </div>
              </Form>
            </CCardBody>
          </CCard>
        </CCol>
      )}
    </Formik>
  );
};

export default LoanVariablesForm;

import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

const GET_NOTIFICATIONS = gql`
  query {
    notifications {
      message
      created_at
    }
  }
`;

const useGetNotifications = () => {
  const { data, loading } = useQuery(GET_NOTIFICATIONS);

  const notifications = get(data, 'notifications', []);

  return [notifications, loading];
};

export default useGetNotifications;

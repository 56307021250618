import React, { useCallback, useMemo } from 'react';
import useLoanMutation from 'hooks/useEditLoan';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-toastify';
import LoanVariablesForm from 'components/loan/LoanVariablesForm';
import { useIntl } from 'react-intl';
import useAdminLoan from 'hooks/useAdminLoan';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { pick } from 'lodash';

function formatInterestRateValues(formData) {
  return Object.keys(formData)
    .filter((key) => key.includes(`interestRate`))
    .reduce((array, key) => {
      const sk1 = parseFloat(key.split('_')[1] / 100);
      const period = parseFloat(key.split('_')[2]);
      array.push({ sk1, period, interest_rate: formData[key] });
      return array;
    }, []);
}

function formatFixedInterestRateValues(formData) {
  return Object.keys(formData)
    .filter((key) => key.includes(`fixedInterestRate`))
    .reduce((array, key) => {
      array.push({ key: key.split('fixedInterestRate_')[1], value: formData[key] });
      return array;
    }, []);
}

const whiteListFormData = [
  'sk1Factor',
  'loanCosts',
  'loanCostsFix',
  'ownFundsRatio',
  'maxLoanRateRatio',
  'interestBandwith',
];

const useChangeLoanHandler = () => {
  const changeLoan = useLoanMutation();
  const { formatMessage: t } = useIntl();

  const handleSubmit = useCallback(
    async (formData, { setSubmitting }) => {
      try {
        const { euribor_6m } = formData;
        const interestRates = formatInterestRateValues(formData);
        const fixedInterestRate = formatFixedInterestRateValues(formData);
        const variableInterestRate = { euribor_6m };
        const whiteListFormValues = pick(formData, whiteListFormData);

        await changeLoan({ ...whiteListFormValues, fixedInterestRate, variableInterestRate, interestRates });
        toast.success(t({ id: 'loan.changes_applied' }));
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [changeLoan, t],
  );
  return handleSubmit;
};

function getInitialValues(loan) {
  if (!loan) return {};

  const { variableInterestRate, interestRates, fixedInterestRate, ...rest } = loan;
  const { euribor_6m } = variableInterestRate;

  let _interestRates = interestRates;
  let _fixedInterestRate = fixedInterestRate;

  _fixedInterestRate = fixedInterestRate.reduce(
    (result, { key, value }) => ({ ...result, [`fixedInterestRate_${key}`]: value }),
    {},
  );

  _interestRates = orderBy(interestRates, ['sk1', 'period'], ['desc', 'desc']);
  _interestRates = groupBy(interestRates, 'sk1');

  const formattedInterestRates = Object.entries(_interestRates).reduce((result, [key, value]) => {
    const interest = value.reduce(
      (res, { period, interest_rate }) => ({ ...res, [`interestRate_${key * 100}_${period}`]: interest_rate }),
      {},
    );
    return { ...result, ...interest };
  }, {});

  return { euribor_6m, ..._fixedInterestRate, ...formattedInterestRates, ...rest };
}

const Loan = () => {
  const onSubmit = useChangeLoanHandler();
  const [loan, loading] = useAdminLoan();
  const initialValues = useMemo(() => getInitialValues(loan), [loan]);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <LoanVariablesForm
      {...{ onSubmit, initialValues }}
      lastUpdatedAt={loan?.last_updated_at}
      lastUpdatedBy={loan?.last_updated_by}
    />
  );
};

export default Loan;

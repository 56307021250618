import React, { useCallback, useMemo } from 'react';
import useUnassignAdmin from 'hooks/admins/useUnassignAdmin';
import { toast } from 'react-toastify';
import useAssignAdmin from 'hooks/admins/useAssignAdmin';
import AdminsTable from 'components/admins/AdminsTable';
import { confirmAlert } from 'react-confirm-alert';
import { useIntl } from 'react-intl';
import 'components/common/AlertOverlay.scss';
import useGetAllAdmins from 'hooks/admins/useGetAdmins';
import LoadingOverlay from 'components/common/LoadingOverlay';

const useUnassignAdminHandler = () => {
  const unassignAdmin = useUnassignAdmin();
  const { formatMessage: t } = useIntl();

  const handleUnassign = useCallback(
    async ({ email, name }) => {
      confirmAlert({
        title: t({ id: 'admins.unassign_admin' }),
        message: t({ id: 'admins.unassign_admin_message' }, { name }),
        buttons: [
          {
            label: t({ id: 'buttons.cancel' }),
          },
          {
            label: t({ id: 'buttons.unassign' }),
            onClick: async () => {
              try {
                await unassignAdmin({ email });
                toast.success(t({ id: 'admins.unassigned' }));
              } catch (error) {
                toast.error(error.message);
              }
            },
          },
        ],
      });
    },
    [t, unassignAdmin],
  );
  return handleUnassign;
};

const useAssignAdminHandler = () => {
  const assignAdmin = useAssignAdmin();
  const { formatMessage: t } = useIntl();

  const handleAssign = useCallback(
    async (email, { resetForm, setSubmitting }) => {
      try {
        await assignAdmin(email);
        toast.success(t({ id: 'admins.assigned' }));
        resetForm();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [assignAdmin, t],
  );
  return handleAssign;
};

const AdminsPage = () => {
  const [data, loading] = useGetAllAdmins();
  const unassignAdmin = useUnassignAdminHandler();
  const assignAdmin = useAssignAdminHandler();
  const { formatMessage: t } = useIntl();

  const fields = useMemo(
    () => [
      { key: 'name', label: t({ id: 'admins.name' }) },
      { key: 'email', label: t({ id: 'admins.email' }) },
      { key: 'unassign', label: t({ id: 'admins.unassign' }) },
    ],
    [t],
  );

  const admins = useMemo(
    () =>
      data.map(({ emails, profile }) => ({
        name: `${profile.firstName} ${profile.lastName}`,
        email: emails[0].address,
      })),
    [data],
  );

  if (loading) {
    return <LoadingOverlay />;
  }

  return <AdminsTable {...{ assignAdmin, unassignAdmin, fields, admins }} />;
};

export default AdminsPage;

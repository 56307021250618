import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_ALL_ADMINS } from './useGetAdmins';

const UNASSIGN_ADMIN_MUTATION = gql`
  mutation deleteAdmin($email: String!) {
    deleteAdmin(email: $email) {
      success
    }
  }
`;

const useUnassignAdmin = () => {
  const [unassignAdminMutation] = useMutation(UNASSIGN_ADMIN_MUTATION);

  const handleUnassign = useCallback(
    async (email) => {
      try {
        await unassignAdminMutation({
          variables: email,
          refetchQueries: [{ query: GET_ALL_ADMINS }],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [unassignAdminMutation],
  );
  return handleUnassign;
};

export default useUnassignAdmin;

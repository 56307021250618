import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

const INIT_2FA = gql`
  query init2FA {
    init2FA
  }
`;

const useInit2FA = () => {
  const { data, loading } = useQuery(INIT_2FA, { fetchPolicy: 'no-cache' });

  const qrCode = get(data, 'init2FA', null);

  return [qrCode, loading];
};

export default useInit2FA;

import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_ALL_ADMINS } from './useGetAdmins';

const ASSIGN_ADMIN_MUTATION = gql`
  mutation addAdmin($email: String!) {
    addAdmin(email: $email) {
      success
    }
  }
`;

const useAssignAdmin = () => {
  const [assignAdminMutation] = useMutation(ASSIGN_ADMIN_MUTATION);

  const handleAssign = useCallback(
    async (email) => {
      try {
        await assignAdminMutation({
          variables: email,
          refetchQueries: [{ query: GET_ALL_ADMINS }],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [assignAdminMutation],
  );

  return handleAssign;
};

export default useAssignAdmin;

import React from 'react';
import { CCard, CCardHeader, CCol, CFormGroup, CInput, CLabel } from '@coreui/react';
import { useIntl } from 'react-intl';
import Loading from 'components/common/Loading';
import useGetHypoNoeApiRequests from 'hooks/hypoNoeApiRequest/useGetHypoNoeApiRequestsReport';
import { useModal } from 'components/common/Modal';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';
import APIRequestsTable from 'components/hypoNoeApiRequestsTable/HypoNoeApiRequestsTable';
import APIRequestDetailsModal from 'components/hypoNoeApiRequestsTable/APIRequestDetailsModal';

const HypoNoeApiRequestsReport = () => {
  const { formatMessage: t } = useIntl();
  const { showModal, hideModal, modalProps, isVisible } = useModal();
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'hypoNoeApiRequestsReport' });
  const { handleChange, debouncedFilterValue, filter } = useFilterValue({ changePage });
  const { requests, total, loading } = useGetHypoNoeApiRequests({ currentPage, filter: debouncedFilterValue });

  return (
    <CCol xs="12" lg="12">
      <CCard>
        <CCardHeader>
          <h4>{t({ id: 'page_titles.hypo_noe_api_requests_report' })}</h4>
        </CCardHeader>

        <CFormGroup>
          <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
          <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
        </CFormGroup>

        {!loading ? (
          <APIRequestsTable {...{ t, showModal, requests, total, changePage, currentPage }} />
        ) : (
          <Loading size={30} />
        )}
        <APIRequestDetailsModal {...{ requestId: modalProps.requestId, hideModal, isVisible }} />
      </CCard>
    </CCol>
  );
};

export default HypoNoeApiRequestsReport;

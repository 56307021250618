import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import useAddLoanConsultant from 'hooks/loan-consultants/useAddLoanConsultant';
import LoanConsultantsTable from 'components/loan-consultants/LoanConsultantsTable';
import { confirmAlert } from 'react-confirm-alert';
import { useIntl } from 'react-intl';
import 'components/common/AlertOverlay.scss';
import useGetLoanConsultants from 'hooks/loan-consultants/useGetLoanConsultants';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useDeleteLoanConsultant from 'hooks/loan-consultants/useDeleteLoanConsultant';

const useDeleteLoanConsultantHandler = () => {
  const deleteLoanConsultant = useDeleteLoanConsultant();
  const { formatMessage: t } = useIntl();

  const handleDelete = useCallback(
    async ({ email, name }) => {
      confirmAlert({
        title: t({ id: 'loan_consultants.delete_loan_consultant' }),
        message: t({ id: 'loan_consultants.delete_loan_consultant_message' }, { name }),
        buttons: [
          {
            label: t({ id: 'buttons.cancel' }),
          },
          {
            label: t({ id: 'buttons.delete' }),
            onClick: async () => {
              try {
                await deleteLoanConsultant({ email });
                toast.success(t({ id: 'loan_consultants.loan_consultant_deleted' }));
              } catch (error) {
                toast.error(error.message);
              }
            },
          },
        ],
      });
    },
    [t, deleteLoanConsultant],
  );
  return handleDelete;
};

const useAddLoanConsultantHandler = () => {
  const addLoanConsultant = useAddLoanConsultant();
  const { formatMessage: t } = useIntl();

  const handleAssign = useCallback(
    async (email, { resetForm, setSubmitting }) => {
      try {
        await addLoanConsultant(email);
        toast.success(t({ id: 'loan_consultants.loan_consultant_added' }));
        resetForm();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [addLoanConsultant, t],
  );
  return handleAssign;
};

const LoanConsultantsPage = () => {
  const [loanConsultantsData, loading] = useGetLoanConsultants();
  const deleteLoanConsultant = useDeleteLoanConsultantHandler();
  const addLoanConsultant = useAddLoanConsultantHandler();
  const { formatMessage: t } = useIntl();

  const fields = useMemo(
    () => [
      { key: 'name', label: t({ id: 'loan_consultants.name' }) },
      { key: 'email', label: t({ id: 'loan_consultants.email' }) },
      { key: 'delete', label: ' ' },
    ],
    [t],
  );

  const loanConsultants = useMemo(
    () =>
      loanConsultantsData.map(({ emails, profile }) => ({
        name: `${profile.firstName} ${profile.lastName}`,
        email: emails[0].address,
      })),
    [loanConsultantsData],
  );

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <LoanConsultantsTable
      onAddLoanConsultant={addLoanConsultant}
      onDeleteLoanConsultant={deleteLoanConsultant}
      {...{ fields, loanConsultants }}
    />
  );
};

export default LoanConsultantsPage;

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import HYPO_NOE_API_REQUEST_PARAMS from 'graphql/fragments/hypoNoeApiRequestFragment';

export const GET_HYPO_NOE_API_REQUEST_DETAILS = gql`
  query hypoNoeApiRequestDetails($requestId: ID!) {
    hypoNoeApiRequestDetails(requestId: $requestId) {
      _id
      request_params {
        ...hypoNoeApiRequestParams
      }
      created_at
    }
  }
  ${HYPO_NOE_API_REQUEST_PARAMS}
`;

const useGetHypoNoeApiRequestDetails = (requestId) => {
  const { data, loading } = useQuery(GET_HYPO_NOE_API_REQUEST_DETAILS, { variables: { requestId } });
  const requestDetails = get(data, 'hypoNoeApiRequestDetails', null);
  return [requestDetails, loading];
};

export default useGetHypoNoeApiRequestDetails;

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import FeedbackForm from 'components/contact/FeedbackForm';
import useSendFeedback from 'hooks/contact/useSendFeedback';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const useSendFeedbackHandler = ({ messageId, page }) => {
  const sendFeedback = useSendFeedback();
  const { formatMessage: t } = useIntl();

  const handleSend = useCallback(
    async (vars, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);
        await sendFeedback({ ...vars, messageId }, page);
        toast.success(t({ id: 'messages.message_sent' }));
        resetForm();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [messageId, page, sendFeedback, t],
  );

  return handleSend;
};

const Feedback = () => {
  const location = useLocation();
  const { id, name, email, page } = location.state.messageDetails;
  const message = location.state.messageDetails.fullMessage;
  const initialValues = { name, email, message: '' };

  const onSubmit = useSendFeedbackHandler({ messageId: id, page });

  return <FeedbackForm {...{ initialValues, message, onSubmit }} />;
};

export default Feedback;

import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

export const CO2_SAVINGS_REPORT = gql`
  query co2SavingsByProjects($limit: Int!, $offset: Int!, $filter: String) {
    co2SavingsByProjects(limit: $limit, offset: $offset, filter: $filter) {
      count
      co2Calculations {
        _id
        co2_emissions_before {
          result {
            energy_ghg_balance_per_year
          }
        }
        co2_emissions_after {
          result {
            energy_ghg_balance_per_year
            co2_savings {
              new_windows
              insulation_top_ceiling
              insulation_basement_ceiling
              insulation_facade
              controlled_living_space_ventilation
              heating_system
              solar_power_system
            }
          }
        }
        project {
          _id
          name
          user {
            emails {
              address
            }
            profile {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

const useCO2SavingsByProjects = ({ currentPage = 1, filter = '' }) => {
  const offset = COUNT_TABLE_RECORDS * (currentPage - 1);

  const { data, loading } = useQuery(CO2_SAVINGS_REPORT, { variables: { filter, offset, limit: COUNT_TABLE_RECORDS } });

  const co2Calculations = useMemo(() => get(data, 'co2SavingsByProjects.co2Calculations', null), [data]);
  const count = useMemo(() => get(data, 'co2SavingsByProjects.count', null), [data]);

  return { co2Calculations, count, loading };
};

export default useCO2SavingsByProjects;

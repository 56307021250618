import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { formatGraphqlErrors } from 'utils/helpers';
import { get } from 'lodash';

const RETRY_API_REQUEST = gql`
  mutation retryHypoNoeApiRequest($requestId: ID!) {
    retryHypoNoeApiRequest(requestId: $requestId) {
      _id
      request_params {
        project_name {
          parameter
          value
        }
        project_type {
          parameter
          value
        }
      }
      response {
        status
        ok
        error
      }
      user {
        profile {
          firstName
          lastName
        }
        emails {
          address
        }
      }
      created_at
    }
  }
`;

const hypoNoeApiResponse = gql`
  fragment hypoNoeRee on HypoNoeRequest {
    response {
      ok
      status
      error
    }
  }
`;

const useRetryApiRequest = () => {
  const [retryApiRequestMutation] = useMutation(RETRY_API_REQUEST);
  const client = useApolloClient();

  const handleRetryRequest = useCallback(
    async (requestId) => {
      try {
        const request = await retryApiRequestMutation({ variables: { requestId } });
        const data = get(request, 'data.retryHypoNoeApiRequest', null);

        if (!data) return;

        client.writeFragment({ id: `HypoNoeRequest___${requestId}`, fragment: hypoNoeApiResponse, data });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [client, retryApiRequestMutation],
  );

  return handleRetryRequest;
};

export default useRetryApiRequest;

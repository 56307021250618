import React from 'react';
import { useIntl } from 'react-intl';
import { CRow, CCol, CCard, CCardHeader, CCardBody, CDataTable } from '@coreui/react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import TextInput from 'components/inputs/TextInput';
import Button from 'components/common/Button';
import s from './AdminsTable.module.scss';

const initialValue = {
  email: '',
};

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email required')
    .email('Email invalid'),
});

const AdminsTable = ({ assignAdmin, unassignAdmin, admins, fields }) => {
  const { formatMessage: t } = useIntl();
  return (
    <CRow>
      <CCol md={12} xl={8} className="mr-auto ml-auto">
        <CCard>
          <CCardHeader>{t({ id: 'admins.title' })}</CCardHeader>

          <Formik initialValues={initialValue} validationSchema={schema} onSubmit={assignAdmin}>
            {({ isSubmitting }) => (
              <>
                <Form>
                  <div className={s.emailField}>
                    <Field type="email" name="email" component={TextInput} required label={t({ id: 'auth.email' })} />
                    <Button disabled={isSubmitting} loading={isSubmitting} color="primary" type="submit">
                      {t({ id: 'admins.add_admin_btn' })}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>

          <CCardBody>
            <CDataTable
              items={admins}
              fields={fields}
              itemsPerPage={20}
              addTableClasses={s.adminsTable}
              scopedSlots={{
                unassign: ({ name, email }) => (
                  <td>
                    <Button
                      color="danger"
                      onClick={() => unassignAdmin({ email, name })}
                      disabled={admins.length === 1}
                    >
                      {t({ id: 'admins.unassign' })}
                    </Button>
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminsTable;

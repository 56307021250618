/* eslint-disable new-cap */
/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import useGetFinancingRequests from 'hooks/useGetFinancingRequests';
import { CCol, CCard, CCardHeader, CCardBody, CButton, CFormGroup, CLabel, CInput } from '@coreui/react';
import { useIntl } from 'react-intl';
import Modal, { useModal } from 'components/common/Modal';
import { jsPDF } from 'jspdf';
import { font } from 'styles/fonts/montserrat-v14-latin-regular-normal';
import Loading from 'components/common/Loading';
import FinancingRequestsTable from 'components/financingRequests/FinancingRequestsTable';
import usePagination from 'hooks/usePagination';
import useFilterValue from 'hooks/useFilterValue';
import s from './FinancingRequests.module.scss';

const FinancingRequests = () => {
  const { formatMessage: t } = useIntl();
  const { showModal, hideModal, isVisible, modalProps } = useModal();
  const { currentPage, changePage } = usePagination({ nameOfRoute: 'financingRequests' });
  const { handleChange, debouncedFilterValue, filter } = useFilterValue({ changePage });
  const { financingRequests, total, loading } = useGetFinancingRequests({
    currentPage,
    filter: debouncedFilterValue,
  });

  const onDownloadClick = useCallback(async (html) => {
    const doc = new jsPDF({ orientation: 'p', unit: 'px' });
    doc.addFileToVFS('montserrat-v14-latin-regular-normal.ttf', font);
    doc.addFont('montserrat-v14-latin-regular-normal.ttf', 'Montserrat', 'normal');
    doc.setFont('Montserrat', 'normal');
    const formattedHtml = html.replace(/sans-serif/g, 'Montserrat');

    const html2canvas = { scale: 0.63 };
    await doc.html(formattedHtml, { margin: 20, x: 15, y: 15, html2canvas });
    doc.save(`Finanzierungsanfrage-${new Date().getTime()}`);
  }, []);

  return (
    <CCol xs="12" lg="12">
      <CCard>
        <CCardHeader>{t({ id: 'page_titles.financing_requests' })}</CCardHeader>
        <CFormGroup className={s.filter}>
          <CLabel htmlFor="filter">{t({ id: 'projects.filter' })}</CLabel>
          <CInput id="filter" type="search" name="filter" value={filter} onChange={handleChange} />
        </CFormGroup>
        <CCardBody>
          {!loading ? (
            <FinancingRequestsTable {...{ t, financingRequests, showModal, total, currentPage, changePage }} />
          ) : (
            <Loading size={30} />
          )}
          <Modal isVisible={isVisible} close={hideModal}>
            <div className={s.messageContainer}>
              <div dangerouslySetInnerHTML={{ __html: modalProps }} />
            </div>
            <div style={{ textAlign: 'right' }}>
              <CButton onClick={() => onDownloadClick(modalProps)} color="primary" style={{ margin: '5px' }}>
                {t({ id: 'financing_requests.download' })}
              </CButton>
            </div>
          </Modal>
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default FinancingRequests;

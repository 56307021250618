import React from 'react';
import useLogin from 'hooks/auth/useLogin';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import { toast } from 'react-toastify';
import LoginForm from '../components/auth/LoginForm';

export default function Login() {
  const login = useLogin();
  const handleSubmit = useAuthFormSubmit(login);

  const onSubmit = React.useCallback(
    async (values, formikActions) => {
      try {
        await handleSubmit({ ...values, isAdmin: true }, formikActions);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [handleSubmit],
  );

  return (
    <div>
      <LoginForm onSubmit={onSubmit} />
    </div>
  );
}

import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import BUDGET_FORM_DATA_FRAGMENT from 'graphql/fragments/budgetFormData';
import { useIntl } from 'react-intl';
import settings from 'config/settings';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

const { rootUrl } = settings;

export const CUSTOMER_DATA_ANALYTICS = gql`
  query customerDataAnalytics($rootUrl: String, $locale: String, $limit: Int!, $offset: Int!, $filter: String) {
    customerDataAnalytics(limit: $limit, offset: $offset, filter: $filter) {
      count
      customerDataRecords {
        user {
          _id
          profile {
            firstName
            lastName
          }
          emails {
            address
            verified
          }
          meta {
            clientIP
          }
        }
        last_project_type
        last_project_created_at
        amount_with_loan_costs
        budgetEstimation {
          _id
          pdf(locale: $locale, url: $rootUrl)
          created_at
          formData {
            ...budgetFormData
          }
        }
      }
    }
  }
  ${BUDGET_FORM_DATA_FRAGMENT}
`;

const useCustomerDataAnalytics = ({ currentPage, filter }) => {
  const { locale } = useIntl();
  const { data, loading } = useQuery(CUSTOMER_DATA_ANALYTICS, {
    variables: {
      locale,
      rootUrl,
      filter,
      limit: COUNT_TABLE_RECORDS,
      offset: (currentPage - 1) * COUNT_TABLE_RECORDS,
    },
  });

  const customerDataRecords = useMemo(() => get(data, 'customerDataAnalytics.customerDataRecords', []), [data]);
  const total = get(data, 'customerDataAnalytics.count', 0);

  return { customerDataRecords, total, loading };
};

export default useCustomerDataAnalytics;

import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { CFormGroup, CLabel, CInput, CFormText, CInvalidFeedback } from '@coreui/react';

const MoneyInput = ({ field, description, form, type = 'number', className, label }) => {
  const { name, onBlur } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  const getClassNameForInput = get(touched, name) && get(errors, name) ? 'form-control is-invalid' : 'form-control';

  const handleChange = React.useCallback(
    (e) => {
      setFieldValue(name, parseFloat(e.target.value));
    },
    [name, setFieldValue],
  );

  const handleOnBlur = React.useCallback(() => {
    onBlur();
    setFieldTouched(name, true);
  }, [name, onBlur, setFieldTouched]);

  return (
    <div>
      <CFormGroup>
        <CLabel htmlFor={name}>{label}</CLabel>
        <CInput
          {...field}
          onBlur={handleOnBlur}
          id={name}
          step="any"
          type={type}
          onChange={handleChange}
          className={cn(getClassNameForInput, className)}
        />
        <CFormText className="help-block">{description}</CFormText>
        {get(touched, name, null) && get(errors, name, null) && (
          <CInvalidFeedback className="help-block">{errors[name]}</CInvalidFeedback>
        )}
      </CFormGroup>
    </div>
  );
};

export default MoneyInput;

import React from 'react';
import { CHeader, CToggler, CHeaderBrand } from '@coreui/react';
import { useSidebar } from 'contexts/AppContext';
import { zIndexNavbar } from 'styles/variables.scss';

const Header = () => {
  const { toggleSidebar, toggleSidebarMobile } = useSidebar();

  return (
    <CHeader withSubheader style={{ zIndex: zIndexNavbar }}>
      <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />
      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CHeaderBrand className="mx-auto d-lg-none" to="/" />
    </CHeader>
  );
};

export default Header;

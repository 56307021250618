import gql from 'graphql-tag';
import get from 'lodash/get';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import { useQuery } from '@apollo/react-hooks';

export const MESSAGES_QUERY = gql`
  query contactMessages($limit: Int!, $offset: Int!, $filter: String) {
    contactMessages(limit: $limit, offset: $offset, filter: $filter) {
      count
      messages {
        _id
        message
        created_at
        status {
          responded
          read
        }
        email
        sender
      }
    }
  }
`;

const useGetMessages = ({ currentPage, filter }) => {
  const { loading, data } = useQuery(MESSAGES_QUERY, {
    variables: { filter, offset: COUNT_TABLE_RECORDS * (currentPage - 1), limit: COUNT_TABLE_RECORDS },
  });

  const messages = get(data, 'contactMessages.messages', []);
  const total = get(data, 'contactMessages.count', 0);

  return { messages, total, loading };
};

export default useGetMessages;

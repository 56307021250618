import { useCallback } from 'react';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const ENABLE_2FA = gql`
  mutation enable2FA($otp: String!) {
    enable2FA(otp: $otp)
  }
`;

const useEnable2FA = () => {
  const [enable2FA] = useMutation(ENABLE_2FA);

  const handleActivate = useCallback(
    async ({ otp }) => {
      try {
        const data = await enable2FA({ variables: { otp }, refetchQueries: ['me'], awaitRefetchQueries: true });
        const isSuccess = get(data, 'data.enable2FA', null);
        return isSuccess;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [enable2FA],
  );
  return handleActivate;
};

export default useEnable2FA;
